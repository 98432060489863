import { Pathname } from 'history';
import { matchPath } from 'react-router-dom';

import { AppRoutesProps } from '@bootstrap/configs/route-access';

export const hasMatchPathRecursive = (pathname: Pathname, routes: AppRoutesProps[]): boolean => {
  return (
    routes.some(({ path }) => matchPath(path, pathname)) ||
    routes.some(({ subRoutes }) => subRoutes && hasMatchPathRecursive(pathname, subRoutes))
  );
};
