import { ReactElement, useMemo } from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { WithBreakpointsProps } from '@bootstrap/configs/breakpoints';
import { AppRoutesProps, deniedScreenSizesByRole } from '@bootstrap/configs/route-access';
import { useUserRoutes } from '@bootstrap/hooks/useUserRoutes';
import { getMatchedPath } from '@bootstrap/utils/getMatchedPath';
import { useUserGroup } from '@hub/auth-provider';
import rotatePhoneImage from '@ui/assets/img/rotate-phone.svg';
import { Notice } from '@ui/notice';

interface IScreenGuardProps extends WithBreakpointsProps {
  routes: AppRoutesProps[];
  children: ReactElement;
}

const ScreenGuard = ({ routes, children, currentBreakpoint }: IScreenGuardProps) => {
  const { userGroup } = useUserGroup();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const { mainRoutes } = useUserRoutes(routes);
  const matchedPath = getMatchedPath(pathname, mainRoutes);
  const isScreenSizeAllowed = useMemo(() => {
    const deniedScreensConfig = deniedScreenSizesByRole[userGroup] || {};
    const deniedPathConfig =
      (pathname in deniedScreensConfig ? deniedScreensConfig[pathname] : deniedScreensConfig['*']) || [];
    return !deniedPathConfig.includes(currentBreakpoint);
  }, [matchedPath?.path, matchedPath?.parent, currentBreakpoint]);

  return isScreenSizeAllowed ? (
    children
  ) : (
    <Notice
      title={formatMessage({ id: 'screenGuard.useBiggerScreen' })}
      image={rotatePhoneImage}
      description={
        <>
          <FormattedMessage id="screenGuard.mobileAccessDeniedFirstLine" />
          <FormattedMessage id="screenGuard.mobileAccessDeniedSecondLine" />
        </>
      }
    />
  );
};

export default withBreakpoints(ScreenGuard);
