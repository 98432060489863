import { useEffect, useLayoutEffect, useState } from 'react';

import { useDebounce } from '@bootstrap/hooks/useDebounce';
import { PdfEmptyView } from '@ui/pdf-preview/PdfEmptyView';

import { IPdfPreviewProps } from './PdfPreview.types';

/**
 * @description
 * A4 aspect ratio found by experimental way
 * It includes fix of present Firefox preview toolbars
 */
export const A4_HEIGHT_RATIO = 1.43;

/**
 * @description Preview minimal height in pixels
 */
export const PREVIEW_MIN_HEIGHT = 150;
/**
 * @description Preview minimal width in pixels
 */
export const PREVIEW_MIN_WIDTH = 150;

export const PdfPreview = ({ link = '', width = 'auto', height }: IPdfPreviewProps) => {
  const [ref, setRef] = useState<HTMLObjectElement | null>(null);
  const [previewHeight, setPreviewHeight] = useState<string | number>(height ?? PREVIEW_MIN_HEIGHT);
  const [previewWidth, setPreviewWidth] = useState<string | number>('auto' === width ? '100%' : width);
  const debouncedHeight = useDebounce<string | number>(previewHeight, 500);
  const debouncedWidth = useDebounce<string | number>(previewWidth, 500);

  useEffect(() => {
    height && setPreviewHeight(height);
  }, [height]);

  useLayoutEffect(() => {
    if (!link) return;

    const recalculateEmbedSize = () => {
      !height &&
        setPreviewHeight(
          ref?.parentElement?.clientWidth ? ref.parentElement.clientWidth * A4_HEIGHT_RATIO : PREVIEW_MIN_HEIGHT,
        );
      setPreviewWidth('auto' === width ? ref?.parentElement?.clientWidth ?? PREVIEW_MIN_WIDTH : width);
    };

    recalculateEmbedSize();

    window.addEventListener('resize', recalculateEmbedSize, false);

    return () => {
      window.removeEventListener('resize', recalculateEmbedSize);
    };
  }, [height, width, link, ref?.parentElement?.clientWidth]);

  return link ? (
    <div>
      <object ref={setRef} data={link} width={debouncedWidth} height={debouncedHeight} style={{ display: 'block' }}>
        <PdfEmptyView />
      </object>
    </div>
  ) : (
    <PdfEmptyView />
  );
};
