import { gql } from '@apollo/client';

export const DEBTOR_LOGO = gql`
  query {
    debtorLogo
  }
`;

export const MERCHANT_LOGO = gql`
  query {
    merchantLogo
  }
`;

export const GET_MERCHANT_COMPANY_NAME = gql`
  query MerchantData {
    merchantData {
      companyDetails {
        officialName
      }
    }
  }
`;

export const GET_DEBTOR_COMPANY_NAME = gql`
  query {
    userCompany {
      officialName
    }
  }
`;
