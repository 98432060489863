import { createGlobalStyle } from 'styled-components';

// TODO: delete classnames.
// Do not add more classnames, use styled-components instead.
export const GlobalStyles = createGlobalStyle`
  a {
    color: var(--color-primary-500);
  }
  td, th {
    vertical-align: middle;
  }

  .align--center {
    align-items: center;
  }

  .formfield--grouped {
    margin-bottom: 0;
  }

  .formfield--no-message .formfield__message {
    display: none;
  }

  .formfield--column-view .formfield__input {
    margin-bottom: 0;
  }

  .formfield__input__after-element,
  .formfield__input__before-element {
    top: 0.67em;
    transform: none;
  }

  .type-mild {
    color: var(--typography-color-mild) !important;
  }

  .input {
    position: static;
    top: 0;
  }

  .checkbox__text:before {
    background: var(--input-default-background);
    border: var(--input-default-border-width) var(--input-default-border-style) var(--input-default-border-color);
  }

  .checkbox__text:hover:before,
  .checkbox__label:hover .checkbox__text:before {
    border-color: var(--input-hover-border-color);
  }

  .cb-hover .checkbox__label {
    margin: -0.75em;
    padding: 0.75em;
    cursor: pointer;
  }

  .cb-hover .checkbox__text {
    padding-bottom: 1.2em;
  }

  .toggler__toggler {
    padding-bottom: 1em;
  }
`;
