import { VatOption } from '@hub/contract-builder/types';

import patterns from './patterns';

export const ALL = 'ALL';

export const DIRECT_PAYMENT_VAT = 0.21; // TODO: should be moved to backend.
export const DIRECT_PAYMENT_TERM = 45;

export const PROJECT_OVERDUE_GAP = 60; // days

export const OUT_OF_RANGE_ERROR = 'break is out of range';

export const DESCRIPTION_MAX_LENGTH = 50;
export const WEEKS_IN_PAST = 7;
export const TABLE_LOADING_HEIGHT = '400px';

export const enum ACTION {
  Close = 'Close',
  ReOpen = 'Re-Open',
  Block = 'Block',
  Unblock = 'Unblock',
  Remove = 'Remove',
}

export const enum PeriodFilters {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Quarter = 'quarter',
}

export const enum RoutePath {
  Accounts = '/accounts',
  AccountsInvitations = '/accounts/invitations',
  BillingRequests = '/billing-requests',
  CollectiveInvoices = '/collective-invoices',
  CollectiveInvoicesUpcoming = '/collective-invoices/upcoming',
  Contracts = '/contracts',
  CreditInvoice = '/credit-invoice/new',
  Invoices = '/invoices',
  Projects = '/projects',
  PurchaseInvoices = '/invoices/purchase',
  TransactionStatements = '/transaction-statements',
  TransactionStatementsArchived = '/transaction-statements/archived',
}

export const enum InvoicePdfType {
  Invoice = 'INVOICE',
  CollectiveInvoice = 'COLLECTIVE_INVOICE',
}

export const All = 'ALL';
export const DISPLAY_FORMAT = 'Y/m/d';
export const DATE_FORMAT_WITH_DASH = 'YYYY/MM/DD';
export const DATE_ZULU_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DROPDOWN_DATE_FORMAT = 'DD/MM/YYYY';
export const WEEKDAY_DATE_FORMAT = 'dddd DD MMMM';
export const FULL_DATE_FORMAT = 'dddd D MMMM';
export const DAY_MONTH_FORMAT = 'DD MMMM';
export const DAY_FORMAT = 'DD';
export const FULL_DAY_NAME = 'dddd';
export const MAX_VALUE = 1000000000;

// TODO: take into account localization
export const ZERO_VAT_OPTION: VatOption = {
  value: 'ZERO',
  label: '0,00%',
  data: {
    level: 'ZERO',
    rate: 0,
    country: 'NL',
  },
};

/** @deprecated */
export const variablesValidationMap = {
  'vendor-kvk-number': patterns.kvk,
  'vendor-sbi-description': patterns.nonBlank,
  'vendor-sbi-code': patterns.nonBlank,
  'vendor-vat-number': patterns.nonBlank,
  'vendor-trade-name': patterns.nonBlank,
  'vendor-branch-number': patterns.nonBlank,
  'vendor-registration-number': patterns.nonBlank,
  'vendor-date-of-birth': patterns.date,
  'vendor-last-name': patterns.nonBlank,
  'vendor-first-name': patterns.nonBlank,
  'vendor-initials': patterns.initials,
  'vendor-address': patterns.nonBlank,
  'vendor-hourly-fee': patterns.number,
  'vendor-signee': patterns.nonBlank,
  'vendor-mobile-phone-number': patterns.phone,
  'vendor-phone-number': patterns.nonBlank,
  'vendor-country': patterns.nonBlank,
  'vendor-company-type': patterns.nonBlank,
  'vendor-company-name': patterns.nonBlank,
  'debtor-kvk-number': patterns.kvk,
  'debtor-sbi-description': patterns.nonBlank,
  'debtor-sbi-code': patterns.nonBlank,
  'debtor-vat-number': patterns.nonBlank,
  'debtor-trade-name': patterns.nonBlank,
  'debtor-branch-number': patterns.nonBlank,
  'debtor-registration-number': patterns.nonBlank,
  'debtor-date-of-birth': patterns.date,
  'debtor-last-name': patterns.nonBlank,
  'debtor-first-name': patterns.nonBlank,
  'debtor-initials': patterns.initials,
  'debtor-address': patterns.nonBlank,
  'debtor-hourly-fee': patterns.number,
  'debtor-signee': patterns.nonBlank,
  'debtor-mobile-phone-number': patterns.phone,
  'debtor-phone-number': patterns.nonBlank,
  'debtor-country': patterns.nonBlank,
  'debtor-company-type': patterns.nonBlank,
  'debtor-company-name': patterns.nonBlank,
  'merchant-kvk-number': patterns.kvk,
  'merchant-sbi-description': patterns.nonBlank,
  'merchant-sbi-code': patterns.nonBlank,
  'merchant-vat-number': patterns.nonBlank,
  'merchant-trade-name': patterns.nonBlank,
  'merchant-branch-number': patterns.nonBlank,
  'merchant-registration-number': patterns.nonBlank,
  'merchant-date-of-birth': patterns.date,
  'merchant-last-name': patterns.nonBlank,
  'merchant-first-name': patterns.nonBlank,
  'merchant-initials': patterns.initials,
  'merchant-address': patterns.nonBlank,
  'merchant-hourly-fee': patterns.number,
  'merchant-signee': patterns.nonBlank,
  'merchant-mobile-phone-number': patterns.phone,
  'merchant-phone-number': patterns.nonBlank,
  'merchant-country': patterns.nonBlank,
  'merchant-company-type': patterns.nonBlank,
  'merchant-company-name': patterns.nonBlank,
  website: patterns.website,
  email: patterns.email,
  phone: patterns.phone,
  'software-period': patterns.nonBlank, //what
  'software-fee': patterns.number,
  'invoice-payment-term': patterns.number,
  'work-list-of-activities': patterns.nonBlank,
  'work-description': patterns.nonBlank,
  'project-number': patterns.nonBlank,
  'project-name': patterns.nonBlank,
  'contract-sign-city': patterns.nonBlank,
  'contract-sign-date': patterns.nonBlank,
  'contract-notice-period': patterns.number,
  'contract-start-date': patterns.date,
  'contract-end-date': patterns.date,
  'contract-number': patterns.nonBlank,
  'contract-description': patterns.nonBlank,
  'contract-title': patterns.nonBlank,
  'processing-agreement-link': patterns.website,
  'additional-terms-and-conditions-link': patterns.website,
  'purchase-order-number': patterns.nonBlank,
};
