import styled from 'styled-components';

export const EmptyViewImageFrameStyles = styled.div`
  background: var(--color-neutral-200);
  border-radius: 50%;
  padding: 8px;
  width: 64px;
  height: 64px;
  margin-bottom: 8px;
`;

export const EmptyViewStyles = styled.div`
  background-color: transparent;
  color: var(--color-neutral-600);
  border-radius: var(--border-radius-default);
`;
