import { gql } from '@apollo/client';

const SubInvoiceSchema = `
  uuid
  identifier
  amountWithVat
  pdf
  directPaymentAmount
  relations {
    issuerName
    issuerDirectorName
  }
  hoursLineItem {
    vat
    vatDeferred
    amount
    amountWithVat
    start
    end
    tariff
    description
    rate
  }
  expenseLineItem {
    date
    vat
    vatDeferred
    amount
    amountWithVat
    tariff
    description
    attachments
  }
  unitLineItem {
    date
    vat
    vatDeferred
    amount
    amountWithVat
    tariff
    description
    quantity
  }
  vatDetails {
    amount
    amountWithVat
    isDeferred
    vat
    vatAmount
  }
`;

export const DEBTOR_GET_COLLECTIVE_INVOICE = gql`
  query getCollectiveInvoice($uuid: UUID!) {
    getCollectiveInvoice(uuid: $uuid) {
      amountWithVat
      created
      dueDate
      identifier
      issueDate
      paid
      paymentTerm
      pdf
      relations {
        projectId
        projectName
      }
      status
      subInvoice {
        ${SubInvoiceSchema}
      }
      ublAttachments
      uuid
      week
      year
    }
  }
`;

export const GET_COLLECTIVE_INVOICE = gql`
  query getCollectiveInvoice($uuid: UUID!) {
    getCollectiveInvoice(uuid: $uuid) {
      amountWithVat
      identifier
      issueDate
      paid
      pdf
      settlementSet {
        amount
        created
        description
        invoice {
          identifier
          uuid
        }
        settledWithId
        settlementType
        status
        uuid
      }
      subInvoice {
        amountWithVat
        directPaymentAmount
        invoiceType
        uuid
      }
      uuid
    }
  }
`;
