import { FormattedMessage } from 'react-intl';

import { EmptyView } from '@ui/empty-view';

import { PdfEmptyViewStyles } from './PdfEmptyView.styles';

export const PdfEmptyView = () => {
  return (
    <PdfEmptyViewStyles>
      <EmptyView
        title={<FormattedMessage id="pdfEmptyView.title" />}
        description={<FormattedMessage id="pdfEmptyView.description" />}
      />
    </PdfEmptyViewStyles>
  );
};
