/** @deprecated */
export default {
  number: /^[0-9]*$/g,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
  initials: /[A-z.?\u00C0-\u024F\u1E00-\u1EFF]{1}/gi,
  nonBlank: /^(?!\s*$).+/gi,
  phone:
    /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/gi,
  kvk: /\d{8}/,
  date: /\d{4}-\d{2}-\d{2}/,
  website: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
};
