import { FormattedMessage } from 'react-intl';

import { Drawer } from '@ui/drawer';
import { PdfPreview } from '@ui/pdf-preview';

interface ISubInvoicePreviewProps {
  subInvoicePdfUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

const SubInvoicePreview = ({ subInvoicePdfUrl, isOpen, onClose }: ISubInvoicePreviewProps) => {
  return (
    <Drawer size="m" isOpen={isOpen} onClose={onClose} title={<FormattedMessage id="field.subInvoicePreview" />}>
      <PdfPreview link={subInvoicePdfUrl} />
    </Drawer>
  );
};

export { SubInvoicePreview };
