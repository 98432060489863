import { Fragment, ReactElement, isValidElement } from 'react';

import { EN_DASH } from '@bootstrap/constants/unicodeCharacters';
import { useMobile } from '@bootstrap/hooks/useMedia';
import { ColorNeutral800 } from '@hub/design-tokens/colors';
import { Flex } from '@ui/flex';
import { Grid } from '@ui/grid';
import { Paper } from '@ui/paper';
import { Typography, TypographyType } from '@ui/typography';

import { IInfoCardProps } from './InfoCard.types';

/**
 * Divides `list` in multiple subarrays if any ReactElement is passed.
 */
export function getFormattedList<Type>(list: Array<Type | ReactElement>) {
  if (list.some((item) => isValidElement(item))) {
    let startIndex = 0;
    const newArr = [];
    list.forEach((item, index) => {
      if (isValidElement(item)) {
        newArr.push(list.slice(startIndex, index));
        newArr.push(item);
        startIndex = index + 1;
      }
    });
    newArr.push(list.slice(startIndex));
    return newArr as Array<ReactElement | Array<Type>>;
  } else {
    return [list] as Array<Array<Type>>;
  }
}

export const InfoCard = ({ title, actions, list, align = 'left', ...paperProps }: IInfoCardProps) => {
  const isMobile = useMobile();
  const formattedList = getFormattedList(list);

  return (
    <Paper hasBorder offset={isMobile ? 16 : 24} {...paperProps}>
      {title && (
        <Flex align="center" gap={16} justify="space-between" offsetBottom={list.length > 0 ? 24 : 0}>
          <Typography as="h3" type={TypographyType.HEADING_S} color={ColorNeutral800}>
            {title}
          </Typography>
          {actions && (
            <Flex gap={8} align="center">
              {actions}
            </Flex>
          )}
        </Flex>
      )}
      {formattedList.map((element, index) => {
        if (Array.isArray(element)) {
          return (
            <Grid template={[1, 1]} gap={16} as="dl" align="start" key={index}>
              {element.map(
                (item, itemIndex) =>
                  item && (
                    <Fragment key={itemIndex}>
                      <Typography as="dt" type={TypographyType.LABEL}>
                        {item.label}
                      </Typography>
                      <Typography as="dd" type={TypographyType.BODY_S} align={align} overflow="wrap">
                        {item.value ?? EN_DASH}
                      </Typography>
                    </Fragment>
                  ),
              )}
            </Grid>
          );
        }
        return element;
      })}
    </Paper>
  );
};
