// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ReactComponent as Server } from '@ui/assets/images/server.svg';
import { Notice } from '@ui/notice';

interface IFeedbackProps {
  title: string | JSX.Element;
  image?: string | React.ReactElement;
  action?: React.ReactElement;
  description?: string | JSX.Element;
}

export const Feedback = ({
  action,
  description,
  title,
  image = <Server width={100} height={100} />,
}: IFeedbackProps) => {
  return <Notice title={title} actions={[action]} description={description} image={image} />;
};
