import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

import { Logout } from '@bootstrap/components/Logout';
import { NotFoundPage } from '@bootstrap/components/NotFoundPage';
import CollectiveInvoiceDetails from '@connect/modules/collective-invoice/CollectiveInvoiceDetails';
import { RoutePath } from '@connect/shared/constants';
import { Offset } from '@ui/offset';
import { Spinner } from '@ui/spinner';

const Accounts = lazy(() => import('../pages/AccountsPage'));
const AccountsInvitations = lazy(() => import('../pages/AccountsInvitationsPage'));
const AccountsOverview = lazy(() => import('../pages/AccountsOverviewPage'));
const BillingRequestDetail = lazy(
  () => import('../modules/billing-request/billing-request-detail/BillingRequestDetail'),
);
const BillingRequestMobileEditor = lazy(
  () => import('../modules/billing-request/billing-request-editor/mobile-editor/BillingRequestMobileCreateForm'),
);
const BillingRequests = lazy(() => import('../modules/billing-requests/BillingRequests'));
const MerchantBulkInvitation = lazy(
  () => import('@connect/modules/merchant/merchant-bulk-invitation/MerchantBulkInvitation'),
);
const ContractDetails = lazy(() => import('../pages/ContractDetailsPage'));
const Contracts = lazy(() => import('../pages/ContractsPage'));
const ContractTemplateDetails = lazy(() => import('../modules/contract-template/ContractTemplateDetails'));
const ContractTemplates = lazy(() => import('../modules/contract-template/ContractTemplates'));
const CreditNoteMobileEditor = lazy(() => import('../modules/credit-note/mobile-editor/CreditNoteMobileEditor'));
const Dashboard = lazy(() => import('../modules/dashboard/Dashboard'));

const CollectiveInvoices = lazy(() => import('../pages/CollectiveInvoicesPage'));
const CollectiveInvoicesOverview = lazy(() => import('../pages/CollectiveInvoicesOverviewPage'));
const CollectiveInvoicesUpcoming = lazy(() => import('../pages/CollectiveInvoicesUpcomingPage'));
const CollectiveInvoiceDetailsMerchant = lazy(
  () => import('../modules/collective-invoice/CollectiveInvoiceDetailsMerchant'),
);

const InvoiceDetails = lazy(() => import('../modules/invoice/invoice-details/InvoiceDetails'));
const Invoices = lazy(() => import('../pages/InvoicesPage'));
const SalesInvoices = lazy(() => import('../pages/SalesInvoicesPage'));
const PurchaseInvoices = lazy(() => import('../pages/PurchaseInvoicesPage'));

const MerchantDetails = lazy(() => import('../modules/merchant/merchant-details/MerchantDetails'));
const MerchantProfile = lazy(() => import('../modules/profile/merchant-profile/MerchantProfile'));
const OnboardingTemplates = lazy(() => import('../modules/onboarding-templates'));
const Organizations = lazy(() => import('../modules/organizations'));
const DebtorProfile = lazy(() => import('../modules/profile/debtor-profile/Profile'));
const Project = lazy(() => import('../modules/project/Project'));
const Projects = lazy(() => import('../modules/projects/Projects'));
const TransactionStatements = lazy(() => import('../pages/TransactionStatementsPage'));
const TransactionStatementsOverview = lazy(() => import('../pages/TransactionStatementsOverviewPage'));
const TransactionStatementsArchive = lazy(() => import('../pages/TransactionStatementsArchivePage'));
const TransactionStatementDetails = lazy(() => import('../pages/TransactionStatementDetailsPage'));
const TransferStatements = lazy(() => import('../pages/TransferStatementsPage'));
const TransferStatementDetails = lazy(() => import('../pages/TransferStatementDetailsPage'));
const Users = lazy(() => import('../modules/users'));
const UserDetail = lazy(() => import('../modules/users/components/user-detail/UserDetails'));

export const AppRouter = () => (
  <Suspense fallback={<Spinner message={<FormattedMessage id="action.loading" />} />}>
    <Routes>
      <Route path="/" element={<Dashboard />} />

      <Route path={RoutePath.BillingRequests} element={<BillingRequests />} />
      <Route path={`${RoutePath.BillingRequests}/:id`} element={<BillingRequestDetail />} />
      <Route path={`${RoutePath.BillingRequests}/new`} element={<BillingRequestMobileEditor />} />

      <Route path="/account-profile" element={<MerchantProfile />} />

      <Route path={RoutePath.Invoices}>
        <Route path={RoutePath.Invoices} element={<Invoices />}>
          <Route index element={<SalesInvoices />} />
          <Route path={RoutePath.PurchaseInvoices} element={<PurchaseInvoices />} />
        </Route>
        <Route path=":id" element={<InvoiceDetails />} />
      </Route>

      <Route path={RoutePath.TransactionStatements}>
        <Route path={RoutePath.TransactionStatements} element={<TransactionStatements />}>
          <Route index element={<TransactionStatementsOverview />} />
          <Route path="archived" element={<TransactionStatementsArchive />} />
        </Route>
        <Route path={`${RoutePath.TransactionStatements}/:id/*`} element={<TransactionStatementDetails />} />
        <Route path={`${RoutePath.TransactionStatementsArchived}/:id`} element={<CollectiveInvoiceDetailsMerchant />} />
      </Route>

      <Route path={RoutePath.CollectiveInvoices}>
        <Route path={RoutePath.CollectiveInvoices} element={<CollectiveInvoices />}>
          <Route index element={<CollectiveInvoicesOverview />} />
          <Route path="upcoming" element={<CollectiveInvoicesUpcoming />} />
        </Route>
        <Route path=":id" element={<CollectiveInvoiceDetails />} />
      </Route>

      <Route path="/contracts" element={<Contracts />} />
      <Route path="/contracts/:id" element={<ContractDetails />} />
      <Route path="/contract-templates" element={<ContractTemplates />} />
      <Route path="/contract-templates/new" element={<ContractTemplateDetails />} />
      <Route path="/contract-templates/:id" element={<ContractTemplateDetails />} />
      <Route path="/credit-invoice/new" element={<CreditNoteMobileEditor />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:id" element={<Project />} />
      <Route path="/onboarding-templates" element={<OnboardingTemplates />} />

      <Route path={RoutePath.Accounts}>
        <Route path={RoutePath.Accounts} element={<Accounts />}>
          <Route index element={<AccountsOverview />} />
          <Route path={RoutePath.AccountsInvitations} element={<AccountsInvitations />} />
        </Route>
        <Route path=":id" element={<MerchantDetails />} />
        <Route path="new" element={<MerchantBulkInvitation />} />
      </Route>
      <Route path="/transfer-statements">
        <Route index element={<TransferStatements />} />
        <Route path=":id/*" element={<TransferStatementDetails />} />
      </Route>
      <Route path="/settings/users" element={<Users />} />
      <Route path="/settings/users/:id" element={<UserDetail />} />
      <Route path="/settings/profile" element={<DebtorProfile />} />
      <Route path="/settings/organizations" element={<Organizations />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="*"
        element={
          <Offset offset={24}>
            <NotFoundPage />
          </Offset>
        }
      />
    </Routes>
  </Suspense>
);
