// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import config from '@bootstrap/configs/env';

config.GRAPHQL_MERCHANT_URL =
  process.env.NX_PUBLIC_GRAPHQL_MERCHANT_URL || 'https://api.finqle.dev/connect/v1/merchant';
config.GRAPHQL_DEBTOR_URL = process.env.NX_PUBLIC_GRAPHQL_DEBTOR_URL || 'https://api.finqle.dev/connect/v1/debtor';
config.MIXPANEL_ID = process.env.NX_PUBLIC_MIXPANEL_ID || '';
config.SENTRY_DSN = process.env.NX_PUBLIC_SENTRY_DSN || '';
config.RESET_PASSWORD_URL = process.env.NX_PUBLIC_RESET_PASSWORD_URL || '';
config.ENV = process.env.NX_PUBLIC_ENV || '';
config.GTM_CODE = process.env.NX_PUBLIC_GTM_CODE;
config.GTM_AUTH = process.env.NX_PUBLIC_GTM_AUTH;
config.GTM_PREVIEW = process.env.NX_PUBLIC_GTM_PREVIEW;

export default config;
