// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { BaseTemplate } from '@bootstrap/components/base-template';
import { LocaleSwitcher } from '@bootstrap/components/locale-switcher';
import { RouteGuard } from '@bootstrap/components/RouteGuard';
import ScreenGuard from '@bootstrap/components/ScreenGuard';
import { UserProfile } from '@bootstrap/components/UserProfile';
import { EN_DASH } from '@bootstrap/constants/unicodeCharacters';
import { useMobile } from '@bootstrap/hooks/useMedia';
import { Locale } from '@bootstrap/types/locale';
import { ILogoTypes } from '@connect/app/App.types';
import { useAuth, useUserGroup } from '@hub/auth-provider';
import { ReactComponent as AccountTree } from '@ui/assets/icons/account_tree.svg';
import { ReactComponent as GroupeAdd } from '@ui/assets/icons/group_add.svg';
import { ReactComponent as Lock } from '@ui/assets/icons/lock.svg';
import { ReactComponent as Logout } from '@ui/assets/icons/logout.svg';
import { ReactComponent as OpenInNew } from '@ui/assets/icons/open-in-new.svg';
import { ReactComponent as Settings } from '@ui/assets/icons/settings.svg';
import { Button } from '@ui/button';
import { ContextMenu, ContextMenuItem } from '@ui/context-menu';
import { Divider } from '@ui/divider';
import { Offset } from '@ui/offset';
import { Popover } from '@ui/popover';
import { Sheet } from '@ui/sheet';

import { CONNECT_SUPPORTED_LOCALES } from './constants';
import { GET_DEBTOR_COMPANY_NAME, GET_MERCHANT_COMPANY_NAME } from './queries';
import { useRoutes } from './routes';
import config from '../configs/constants';

interface IMainTemplateProps {
  children: React.ReactNode;
  logos?: ILogoTypes;
  setLanguage: (locale: Locale) => void;
  isDefaultLogo: boolean;
}

export const MainTemplate = (props: IMainTemplateProps) => {
  const { isMerchant, isBillingRequestApprover, isDebtor, userGroup } = useUserGroup();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const routesConfig = useRoutes();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const { tokenParsed } = useAuth();

  // This is needed for the marketing purpose.
  localStorage.setItem('isMerchant', JSON.stringify(isMerchant));
  localStorage.setItem('email', JSON.stringify(tokenParsed?.email));
  localStorage.setItem('name', JSON.stringify(tokenParsed?.name));

  const onCloseUserProfile = () => {
    setIsUserProfileOpen(false);
    setIsMobileMenuOpen(false);
  };

  const { data: { merchantData } = {} } = useQuery(GET_MERCHANT_COMPANY_NAME, {
    skip: !isMerchant,
    fetchPolicy: 'cache-and-network',
  });

  const { data: { userCompany } = {} } = useQuery(GET_DEBTOR_COMPANY_NAME, {
    skip: isMerchant,
    fetchPolicy: 'cache-and-network',
  });

  // Controller group user is Debtor
  const companyName = isMerchant ? merchantData?.companyDetails?.officialName : userCompany?.officialName;

  const routes = routesConfig
    .map((route) => {
      const filteredSubRoutes = route.subRoutes?.filter((subRoute) => subRoute.groups?.includes(userGroup));

      return {
        ...route,
        subRoutes: filteredSubRoutes,
      };
    })
    .filter((route) => route.groups?.includes(userGroup));

  const onLocaleSwitch = (locale: Locale) => {
    props.setLanguage(locale);
  };

  const ContextMenuContent = () => {
    return (
      <ContextMenu offset={isMobile ? 0 : 16}>
        <LocaleSwitcher onChange={onLocaleSwitch} locales={CONNECT_SUPPORTED_LOCALES} />
        <Divider negativeMargin="-16px" />
        {!isBillingRequestApprover && (
          <ContextMenuItem
            label={<FormattedMessage id={isMerchant ? 'field.profileSettings' : 'field.companyProfileSettings'} />}
            onClick={() => {
              navigate(isMerchant ? '/account-profile' : '/settings/profile');
              onCloseUserProfile();
            }}
            iconLeft={<Settings />}
          />
        )}
        {isDebtor && (
          <>
            <ContextMenuItem
              label={<FormattedMessage id="field.users" />}
              onClick={() => {
                navigate('/settings/users');
                onCloseUserProfile();
              }}
              iconLeft={<GroupeAdd />}
            />
            <ContextMenuItem
              label={<FormattedMessage id="field.organizations" />}
              onClick={() => {
                navigate('/settings/organizations');
                onCloseUserProfile();
              }}
              iconLeft={<AccountTree />}
            />
          </>
        )}
        <ContextMenuItem
          label={<FormattedMessage id="field.resetPassword" />}
          onClick={() => {
            window.open(config.RESET_PASSWORD_URL, '_blank');
            onCloseUserProfile();
          }}
          iconLeft={<Lock />}
          iconRight={<OpenInNew />}
        />
        <Divider negativeMargin="-16px" />
        <Button variant="secondary" onClick={() => navigate('/logout')} iconLeft={<Logout />} fullWidth>
          <FormattedMessage id="action.logout" />
        </Button>
      </ContextMenu>
    );
  };

  return (
    <BaseTemplate
      routes={routes}
      isLiveChatEnabled
      isMobileMenuOpen={isMobileMenuOpen}
      toggleMobileMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      {...props}
      userProfile={
        isMobile ? (
          <>
            <UserProfile
              userName={tokenParsed?.name}
              userCompany={companyName}
              onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
            />
            <Sheet
              isOpen={isUserProfileOpen}
              onClose={() => setIsUserProfileOpen(false)}
              title={tokenParsed?.name}
              subtitle={companyName ?? EN_DASH}
            >
              <ContextMenuContent />
            </Sheet>
          </>
        ) : (
          <Popover
            transform={{ left: 8 }}
            onClickOutside={onCloseUserProfile}
            isOpen={isUserProfileOpen}
            positions={['top']}
            trigger={
              <UserProfile
                userName={tokenParsed?.name}
                userCompany={companyName}
                onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
              />
            }
            content={<ContextMenuContent />}
          />
        )
      }
      desktopLogo={props.logos?.desktop}
      mobileLogo={props.logos?.mobile}
    >
      <RouteGuard routes={routesConfig}>
        <Offset offset={isMobile ? [32, 16] : 24}>
          <ScreenGuard routes={routesConfig}>{props.children}</ScreenGuard>
        </Offset>
      </RouteGuard>
    </BaseTemplate>
  );
};
