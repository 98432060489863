import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { isProduction, isSandbox } from '@bootstrap/constants/env';

export function initializeGTM(args: TagManagerArgs) {
  if (isProduction) {
    return TagManager.initialize({
      gtmId: args.gtmId,
    });
  }

  if (isSandbox) {
    return TagManager.initialize(args);
  }
}
