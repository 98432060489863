import { useMobile } from '@bootstrap/hooks/useMedia';
import { ColorNeutral600 } from '@hub/design-tokens';
import { ReactComponent as NotFound } from '@ui/assets/images/scan-delete.svg';
import { Grid } from '@ui/grid';
import { Offset } from '@ui/offset';
import { Typography, TypographyType } from '@ui/typography';

import { EmptyViewImageFrameStyles, EmptyViewStyles } from './EmptyView.styles';
import { IEmptyViewProps } from './EmptyView.types';

export const EmptyView = ({ title, description, action }: IEmptyViewProps) => {
  const isMobile = useMobile();
  return (
    <EmptyViewStyles>
      <Grid justify="center" align="stretch">
        <EmptyViewImageFrameStyles>
          <NotFound />
        </EmptyViewImageFrameStyles>
        <Offset offsetTop={16}>
          <Typography
            type={isMobile ? TypographyType.HEADING_S : TypographyType.HEADING_M}
            color={ColorNeutral600}
            align="center"
          >
            {title}
          </Typography>
        </Offset>
        {description && (
          <Offset offsetTop={8}>
            <Typography
              type={isMobile ? TypographyType.BODY_M_REGULAR : TypographyType.BODY_L_REGULAR}
              color={ColorNeutral600}
              align="center"
            >
              {description}
            </Typography>
          </Offset>
        )}
        {action && <Offset offsetTop={16}>{action}</Offset>}
      </Grid>
    </EmptyViewStyles>
  );
};
