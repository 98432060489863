export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  GenericScalar: { input: any; output: any };
  JSONString: { input: any; output: any };
  UUID: { input: any; output: any };
};

export enum BillingRequestStatus {
  Approved = 'APPROVED',
  Disapproved = 'DISAPPROVED',
  Draft = 'DRAFT',
  Invoiced = 'INVOICED',
  Submitted = 'SUBMITTED',
}

export enum CollectiveInvoiceTypeChoices {
  CollectiveDebtor = 'COLLECTIVE_DEBTOR',
  CollectiveMerchant = 'COLLECTIVE_MERCHANT',
  CollectiveProduct = 'COLLECTIVE_PRODUCT',
}

export enum DebtorMerchantContractStatusChoices {
  ActionRequired = 'ACTION_REQUIRED',
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Terminated = 'TERMINATED',
  Upcoming = 'UPCOMING',
}

export enum InvoiceStatusChoices {
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Disapproved = 'DISAPPROVED',
  Finalized = 'FINALIZED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
  PendingDebtorApproval = 'PENDING_DEBTOR_APPROVAL',
  Submitted = 'SUBMITTED',
  ToBePaid = 'TO_BE_PAID',
}

export enum InvoiceTypeChoices {
  FactorMerchant = 'FACTOR_MERCHANT',
  FactorProduct = 'FACTOR_PRODUCT',
  FactorVendor = 'FACTOR_VENDOR',
  MerchantDebtor = 'MERCHANT_DEBTOR',
  ProductDebtor = 'PRODUCT_DEBTOR',
  ProductMerchant = 'PRODUCT_MERCHANT',
  ProductVendor = 'PRODUCT_VENDOR',
  VendorDebtor = 'VENDOR_DEBTOR',
  VendorMerchant = 'VENDOR_MERCHANT',
}

export enum PaymentStatusChoices {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
}

export enum ProjectStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Overdue = 'OVERDUE',
  Upcoming = 'UPCOMING',
}

export enum SettlementStatus {
  Closed = 'CLOSED',
  Created = 'CREATED',
  Opened = 'OPENED',
  Settled = 'SETTLED',
  Split = 'SPLIT',
}

export enum SettlementTypeChoices {
  Advance = 'ADVANCE',
  AdvancePayback = 'ADVANCE_PAYBACK',
  BadDebt = 'BAD_DEBT',
  BankTransaction = 'BANK_TRANSACTION',
  Correction = 'CORRECTION',
  Custom = 'CUSTOM',
  Depot = 'DEPOT',
  DepotPayable = 'DEPOT_PAYABLE',
  PspTransaction = 'PSP_TRANSACTION',
  Suspense = 'SUSPENSE',
  UsedDepot = 'USED_DEPOT',
}

export enum TransactionStatementStatusChoices {
  Created = 'CREATED',
  Paid = 'PAID',
}

export type UnitLineItemsArgumentsInputObject = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountWithVat?: InputMaybe<Scalars['Float']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  tariff?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
  vatDeferred?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VatRateLevelChoices {
  Deferred = 'DEFERRED',
  Reduced_1 = 'REDUCED_1',
  Reduced_2 = 'REDUCED_2',
  Reduced_3 = 'REDUCED_3',
  Standard = 'STANDARD',
  Zero = 'ZERO',
}
