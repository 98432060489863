import styled, { css } from 'styled-components';

import { TypographyStyles } from '@ui/typography/Typography.styles';

import { ITabsProps } from './Tabs.types';

export const TabsStyles = styled.div<{ hasBorder: ITabsProps['hasBorder'] }>`
  overflow-x: auto;
  white-space: nowrap;
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      box-shadow: inset 0 -1px 0 0 var(--color-neutral-300);
    `}
`;

export const TabStyles = styled.button`
  flex-shrink: 0;
  padding: 12px 16px 10px;
  appearance: none;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &:hover:not([aria-selected='true']) > ${TypographyStyles} {
    color: var(--color-neutral-700);
  }

  &:active > ${TypographyStyles} {
    color: var(--color-primary-700);
  }

  &[aria-selected='true'] {
    border-bottom: 2px solid var(--color-primary-700);
  }

  &:focus-visible {
    outline: auto;
    outline-offset: -1px;
  }
`;
