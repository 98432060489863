import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { renderAmount } from '@bootstrap/utils/renderAmount';
import { TABLE_LOADING_HEIGHT } from '@connect/shared/constants';
import { ShadowDefault } from '@hub/design-tokens';
import { ReactComponent as Preview } from '@ui/assets/icons/eye.svg';
import { Button } from '@ui/button';
import { Paper } from '@ui/paper';
import { ITableColumn, Table } from '@ui/table';

import { SubInvoicePreview } from './sub-invoices/SubInvoicePreview';

const CollectiveInvoiceBreakdown = ({ data }: { data: SubInvoiceInDebtorCollectiveInvoice[] }) => {
  const { formatMessage } = useIntl();
  const [isPreviewShown, setIsPreviewShown] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState<string>();

  const columnNamesForCollectiveInvoices: ITableColumn<SubInvoiceInDebtorCollectiveInvoice>[] = [
    {
      name: <FormattedMessage id="field.invoiceNumber" />,
      id: 'identifier',
      cell: (row) => row.identifier,
    },
    {
      name: <FormattedMessage id="field.supplier" />,
      id: 'issuerName',
      cell: (row) => row.relations?.issuerName,
    },
    {
      name: <FormattedMessage id="field.contact" />,
      id: 'issuerDirectorName',
      cell: (row) => row.relations?.issuerDirectorName,
    },
    {
      name: <FormattedMessage id="field.totalAmount" />,
      id: 'amountWithVat',
      cell: (row) => renderAmount({ amount: row.amountWithVat }),
      right: true,
    },
    {
      name: <FormattedMessage id="field.action" />,
      id: 'action',
      cell: (row) => (
        <Button
          iconLeft={<Preview />}
          variant="secondary"
          aria-label={formatMessage({ id: 'action.preview' })}
          onClick={() => {
            setInvoiceUrl(row.pdf as string);
            setIsPreviewShown(true);
          }}
          size="small"
        >
          <FormattedMessage id="field.preview" />
        </Button>
      ),
      right: true,
    },
  ];

  return (
    <>
      <Paper boxShadow={ShadowDefault}>
        <Table
          loadingHeight={TABLE_LOADING_HEIGHT}
          data={data || []}
          columns={columnNamesForCollectiveInvoices}
          keyField="uuid"
        />
      </Paper>
      <SubInvoicePreview
        subInvoicePdfUrl={invoiceUrl as string}
        isOpen={isPreviewShown}
        onClose={() => setIsPreviewShown(false)}
      />
    </>
  );
};

export { CollectiveInvoiceBreakdown };
