import { useMemo } from 'react';

import { AppRoutesProps } from '@bootstrap/configs/route-access';
import { IUserGroupRoutes } from '@bootstrap/types/routes';
import { useAuth } from '@hub/auth-provider';

/**
 * All the allowed routes for the set of roles from keycloak
 * Routes filtered depends on user group (role)
 *
 * @param routes - Array of routes
 * @returns List of allowed routes for a group (role)
 */
export const useUserRoutes = (routes: AppRoutesProps[]): IUserGroupRoutes => {
  const { getGroups } = useAuth();
  const userGroups = getGroups();

  return useMemo(() => {
    /**
     * User group related routes. Routes that has user group in groups node
     */
    const userGroupRoutes = routes.filter(
      ({ groups }) => !groups || groups.find((group) => userGroups.includes(group)),
    );

    /**
     * User group related top level routes (without parent)
     * @example: /billing-requests
     */
    const mainRoutes = userGroupRoutes.filter(({ parent }) => !parent);

    /**
     * User group non-related routes that has parent section in path
     * But routes will not be displayed as submenu item in sidebar menu
     * @example: /billing-requests/new has parent /billing-requests but route is not present in sidebar at all
     */
    const childRoutes = userGroupRoutes.filter(({ parent }) => !!parent);

    /**
     * User group related sub routes. Could be uses in submenu item in sidebar menu
     * @example: /settings/profile has parent menu route /settings
     */
    const subMenuRoutes: AppRoutesProps[] = mainRoutes
      .filter((route) => !!route.subRoutes)
      .flatMap((route) => route.subRoutes ?? [])
      .filter((route) => route.parent);

    return {
      availableRoutes: userGroupRoutes,
      mainRoutes,
      subMenuRoutes,
      childRoutes,
      allSubRoutes: [...subMenuRoutes, ...childRoutes],
    };
  }, [userGroups, routes]);
};
