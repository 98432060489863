import { useLocation } from 'react-router-dom';

import { NotFoundPage } from '@bootstrap/components/NotFoundPage';
import { useUserRoutes } from '@bootstrap/hooks/useUserRoutes';
import { IRouteGuardProps } from '@bootstrap/types/routes';
import { hasMatchPathRecursive } from '@bootstrap/utils/hasMatchPathRecursive';

export const RouteGuard = ({ routes, children }: IRouteGuardProps) => {
  const { pathname } = useLocation();
  const { availableRoutes } = useUserRoutes(routes);

  // Check if one of the routes or subroute match current path
  const isPathAllowed = hasMatchPathRecursive(pathname, availableRoutes);

  return (
    <>
      {isPathAllowed && children}
      {!isPathAllowed && <NotFoundPage />}
    </>
  );
};
