import ReactBreakpoints from 'react-breakpoints';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import breakpoints from '@bootstrap/configs/breakpoints';
import { initializeGTM } from '@bootstrap/utils/gtm';
import { initializeMixpanel } from '@bootstrap/utils/mixpanel';
import { initializeSentry } from '@bootstrap/utils/sentry';

import { ConnectApp } from './app/ConnectApp';
import config from './configs/constants';
// import { worker } from './mocks/mocks';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// FIXME: enable it only for local/staging environments
// // MSW tool
// if (process.env.NX_PUBLIC_ENV === 'local' || process.env.NX_PUBLIC_ENV === 'development')
//   worker.start({ onUnhandledRequest: 'bypass' });

initializeSentry(config.SENTRY_DSN);
initializeMixpanel(config.MIXPANEL_ID);
initializeGTM({ gtmId: config.GTM_CODE, auth: config.GTM_AUTH, preview: config.GTM_PREVIEW });

root.render(
  <BrowserRouter>
    <ReactBreakpoints breakpoints={breakpoints}>
      <ConnectApp />
    </ReactBreakpoints>
  </BrowserRouter>,
);
