import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getFileNameFromLink } from '@bootstrap/utils/getFileNameFromLink';
import { ShadowDefault } from '@hub/design-tokens';
import { ColorNeutral800 } from '@hub/design-tokens/colors';
import { ReactComponent as Download } from '@ui/assets/icons/download.svg';
import { ReactComponent as Preview } from '@ui/assets/icons/eye.svg';
import { Button } from '@ui/button';
import { Drawer } from '@ui/drawer';
import { Grid } from '@ui/grid';
import { Offset } from '@ui/offset';
import { Paper } from '@ui/paper';
import { PdfPreview } from '@ui/pdf-preview';
import { Typography, TypographyType } from '@ui/typography';

//TODO: add type
export function CollectiveInvoiceAttachments({ collectiveInvoice }: { collectiveInvoice: any }) {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState<string>();

  const specification = collectiveInvoice.specification ?? '';
  const attachments = [
    ...(collectiveInvoice.attachments ?? []),
    ...(collectiveInvoice.ublAttachments ?? []),
    ...(specification.length > 0 ? [{ url: specification }] : []),
  ];

  if (attachments.length === 0) {
    return null;
  }

  return (
    <Paper boxShadow={ShadowDefault} offset={24}>
      <Offset offsetBottom={24}>
        <Typography as="h3" type={TypographyType.HEADING_S} color={ColorNeutral800}>
          <FormattedMessage id="field.attachments" />
        </Typography>
      </Offset>

      <Grid rowGap={8}>
        {attachments.map((attachment, key) => {
          const url = attachment.url || attachment;
          return (
            <Grid gap={8} align="center" template="1fr 40px 40px" key={key}>
              <Typography type={TypographyType.BODY_S} overflow="truncate">
                {getFileNameFromLink(url)}
              </Typography>
              <Button
                iconLeft={<Download />}
                variant="secondary"
                aria-label={formatMessage({ id: 'action.download' })}
                onClick={() => window.open(url, '_blank')}
              />
              <Button
                iconLeft={<Preview />}
                variant="secondary"
                aria-label={formatMessage({ id: 'action.preview' })}
                onClick={() => {
                  setCurrentAttachment(url);
                  setIsOpen(true);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Drawer
        isOpen={isOpen}
        size="m"
        title={formatMessage({ id: 'invoiceDetails.attachmentPreview' })}
        onClose={() => setIsOpen(false)}
      >
        <PdfPreview link={currentAttachment} />
      </Drawer>
    </Paper>
  );
}
