// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { InvoiceTypeChoices } from '@connect/types/merchant-generated.enum';

// TODO: check if it can be removed
export const getCollectiveInvoiceSummary = (
  invoice: MerchantCollectiveInvoicesType,
): {
  totalAmount: number;
  directPaymentAmount: number;
} => {
  const { subInvoice: invoices = [], settlementSet: settlements = [] } = invoice ?? {};
  const nonFactorInvoices = invoices.filter((invoice) => invoice.invoiceType !== InvoiceTypeChoices.FactorMerchant);
  const factorInvoices = invoices.filter((invoice) => invoice.invoiceType === InvoiceTypeChoices.FactorMerchant);
  const settlementsAmount = settlements.length
    ? settlements.reduce((sum, settlement) => sum + Number(settlement.amount), 0)
    : 0;

  const totalAmount = nonFactorInvoices.reduce((sum, invoice) => sum + invoice.amountWithVat, 0) + settlementsAmount;

  const factorFeeAmount = factorInvoices.reduce((sum, invoice) => sum + invoice.amountWithVat, 0);

  const directPaymentWithFactorFee = nonFactorInvoices.reduce((sum, invoice) => sum + invoice.directPaymentAmount, 0);

  return {
    totalAmount,
    directPaymentAmount: directPaymentWithFactorFee + settlementsAmount - factorFeeAmount,
  };
};

export const getCollectiveInvoiceVatAmount = (invoices: SubInvoiceInDebtorCollectiveInvoice[]): number => invoices.reduce((acc, invoice) => acc + invoice.vatDetails.reduce((acc1, vatItem) => acc1 + vatItem.vatAmount, 0), 0);
