import { flattenMessages } from '@bootstrap/utils/flattenMessages';

export async function loadLocaleData(locale?: string): Promise<Record<string, string>> {
  const [
    bootstrap,
    main,
    companyStructureBuilderMessages,
    onboardingBuilderMessages,
    contractBuilderMessages,
  ] = await Promise.all([
    import(`../../../../libs/bootstrap/src/i18n/${locale}.json`), // FIXME: why `@bootstrap/i18n/${locale}.json` doesn't work?
    import(`../i18n/${locale}.json`),
    import(`../../../../libs/company-structure-builder/src/i18n/${locale}.json`),
    import(`../../../../libs/onboarding-builder/src/i18n/${locale}.json`),
    import(`../../../../libs/contract-builder/src/i18n/${locale}.json`),
  ]);

  return {
    ...flattenMessages(bootstrap.default),
    ...flattenMessages(main.default),
    ...companyStructureBuilderMessages.default,
    ...onboardingBuilderMessages.default,
    ...contractBuilderMessages.default,
  };
}
