import { KeyboardEvent, useEffect, useState } from 'react';

import { ColorNeutral600, ColorPrimary700 } from '@hub/design-tokens/colors';
import { Offset } from '@ui/offset';
import { Typography, TypographyType } from '@ui/typography';

import { TabsStyles, TabStyles } from './Tabs.styles';
import { ITabsProps } from './Tabs.types';

export function Tabs<Value extends string = string>({ tabs, children, hasBorder, value, onChange }: ITabsProps<Value>) {
  const [selectedTab, setSelectedTab] = useState(value ? tabs.findIndex((tab) => tab.value === value) : 0);
  const [focusedTab, setFocusedTab] = useState(selectedTab);

  const onKeydown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.code === 'ArrowRight') {
      const isLastTab = focusedTab === tabs.length - 1;
      if (isLastTab) {
        setFocusedTab(0);
        return document.getElementById(`tab-${0}`)?.focus();
      }
      const newFocusedTab = focusedTab + 1;
      setFocusedTab(newFocusedTab);
      document.getElementById(`tab-${newFocusedTab}`)?.focus();
    }

    if (event.code === 'ArrowLeft') {
      const isFirstTab = focusedTab === 0;
      if (isFirstTab) {
        setFocusedTab(tabs.length - 1);
        return document.getElementById(`tab-${tabs.length - 1}`)?.focus();
      }
      const newFocusedTab = focusedTab - 1;
      setFocusedTab(newFocusedTab);
      document.getElementById(`tab-${newFocusedTab}`)?.focus();
    }

    if (event.code === 'Enter' || event.code === 'Space') {
      setSelectedTab(focusedTab);
    }
  };

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => tab.value === value);
    newTabValue !== -1 && setSelectedTab(newTabValue);
  }, [tabs, value]);

  return (
    <div>
      <TabsStyles role="tablist" hasBorder={hasBorder}>
        {tabs.map((tab, index) => {
          const isSelectedTab = selectedTab === index;
          return (
            <TabStyles
              tabIndex={isSelectedTab ? 0 : -1}
              id={`tab-${index}`}
              role="tab"
              key={index}
              onClick={() => {
                setSelectedTab(index);
                setFocusedTab(index);
                onChange?.(tab);
              }}
              aria-selected={isSelectedTab}
              aria-controls={`tabpanel-${index}`}
              onKeyDown={onKeydown}
            >
              {isSelectedTab ? (
                <Typography type={TypographyType.BODY_M_BOLD} color={ColorPrimary700} as="span">
                  {tab.label}
                </Typography>
              ) : (
                <Typography type={TypographyType.BODY_M_REGULAR} color={ColorNeutral600} as="span">
                  {tab.label}
                </Typography>
              )}
            </TabStyles>
          );
        })}
      </TabsStyles>
      {children && (
        <Offset role="tabpanel" id={`tabpanel-${selectedTab}`} aria-labelledby={`tab-${selectedTab}`} offsetTop={24}>
          {children[selectedTab]}
        </Offset>
      )}
    </div>
  );
}
