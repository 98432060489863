import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { CollectiveInvoiceAttachments } from '@bootstrap/components/collective-invoice/CollectiveInvoiceAttachments';
import { formatDate } from '@bootstrap/utils';
import { renderAmount } from '@bootstrap/utils/renderAmount';
import { captureException } from '@bootstrap/utils/sentry';
import { Feedback } from '@connect/shared/components/Feedback';
import { RoutePath } from '@connect/shared/constants';
import { InvoiceStatusChoices } from '@connect/types/debtor-generated.enum';
import { ShadowDefault } from '@hub/design-tokens';
import { ReactComponent as Download } from '@ui/assets/icons/download.svg';
import { ReactComponent as Payments } from '@ui/assets/icons/payments.svg';
import { Button } from '@ui/button';
import { Flex } from '@ui/flex';
import { Grid } from '@ui/grid';
import { InfoCard } from '@ui/info-card';
import { PageHeader } from '@ui/page-header';
import { PdfPreview } from '@ui/pdf-preview';
import { Skeleton } from '@ui/skeleton';
import { Status } from '@ui/status';
import { Tabs } from '@ui/tabs';
import { Toast, toast } from '@ui/toast';

import { DEBTOR_GET_COLLECTIVE_INVOICE } from './CollectiveInvoice.queries';
import { CollectiveInvoiceBreakdown } from './CollectiveInvoiceBreakdown';
import { CREATE_PAYMENT_TRANSACTION } from '../collective-invoices/CollectiveInvoices.mutations';
import { getCollectiveInvoiceVatAmount } from '../collective-invoices/CollectiveInvoices.utils';
import { InvoiceStatus } from '../invoice/InvoiceStatus';

enum TabNames {
  Overview = 'OVERVIEW',
  Invoices = 'INVOICES',
}

const tabs = [
  { label: <FormattedMessage id="field.overview" />, value: TabNames.Overview },
  { label: <FormattedMessage id="field.invoices" />, value: TabNames.Invoices },
];

const CollectiveInvoiceDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: { getCollectiveInvoice: collectiveInvoice } = { getCollectiveInvoice: {} },
    loading,
    error,
    refetch,
  } = useQuery<{ getCollectiveInvoice: DebtorCollectiveInvoiceType }>(DEBTOR_GET_COLLECTIVE_INVOICE, {
    variables: { uuid: id },
  });

  const isPaid = [InvoiceStatusChoices.Closed, InvoiceStatusChoices.Finalized].includes(
    collectiveInvoice.status as InvoiceStatusChoices,
  );

  const [createPaymentTransaction, { loading: createPaymentTransactionLoading }] = useMutation(
    CREATE_PAYMENT_TRANSACTION,
    {
      onError: (e) => {
        captureException(e, {
          extra: { uuid: collectiveInvoice.uuid },
        });
        const toastId = toast.custom(
          <Toast
            description={e.message}
            message={<FormattedMessage id="error.somethingWentWrong" />}
            onClose={() => toast.dismiss(toastId)}
            type="error"
          />,
        );
      },
      onCompleted: (result) => {
        window.open(result.createPaymentTransaction.url, '_self');
      },
    },
  );

  const onPay = () => {
    createPaymentTransaction({ variables: { collectiveInvoiceUuids: [collectiveInvoice.uuid] } });
    return;
  };

  if (loading)
    return (
      <>
        <Skeleton height="48px" width="280px" offsetBottom={24} />
        <Skeleton height="36px" width="300px" offsetBottom={24} />
        <Grid template={[2, 1]} gap={24}>
          <Skeleton height="800px" />
          <Skeleton height="370px" />
        </Grid>
      </>
    );

  if (error)
    return (
      <Feedback
        title={<FormattedMessage id="message.errorServerError" />}
        description={<FormattedMessage id="message.errorFetchingData" />}
        action={
          <Button onClick={refetch} key="refetch">
            <FormattedMessage id="action.refetch" />
          </Button>
        }
      />
    );

  return (
    <>
      <PageHeader
        title={<FormattedMessage id="field.collectiveInvoiceX" values={{ x: collectiveInvoice.identifier }} />}
        status={<InvoiceStatus isPaid={isPaid} />}
        addon={
          <Flex gap={16}>
            {!!collectiveInvoice.pdf && (
              <Button
                disabled={loading}
                type="button"
                variant="secondary"
                iconLeft={<Download />}
                onClick={() => window.open(collectiveInvoice.pdf as string, '_blank')}
              >
                <FormattedMessage id="action.download" />
              </Button>
            )}
            {collectiveInvoice.status === InvoiceStatusChoices.Open && (
              <Button
                disabled={
                  loading ||
                  !collectiveInvoice.amountWithVat ||
                  collectiveInvoice.amountWithVat <= 0 ||
                  !!collectiveInvoice.paid
                }
                iconLeft={<Payments />}
                isLoading={createPaymentTransactionLoading}
                onClick={onPay}
                variant="positive"
              >
                <FormattedMessage id="action.pay" />
              </Button>
            )}
          </Flex>
        }
      />
      <Tabs
        tabs={tabs}
        value={searchParams.get('tab')}
        onChange={(tab) => {
          searchParams.set('tab', tab.value);
          setSearchParams(searchParams);
        }}
        hasBorder
      >
        <Grid template={[2, 1]} gap={24} align="start">
          <PdfPreview link={collectiveInvoice.pdf} />
          <Grid gap={24}>
            <InfoCard
              align="right"
              hasBorder={false}
              boxShadow={ShadowDefault}
              title={<FormattedMessage id="field.invoiceDetails" />}
              list={[
                {
                  label: <FormattedMessage id="field.invoiceNumber" />,
                  value: collectiveInvoice.identifier,
                },
                {
                  label: <FormattedMessage id="field.project" />,
                  value: (
                    <Link to={`${RoutePath.Projects}/${collectiveInvoice.relations?.projectId}`}>
                      {collectiveInvoice.relations?.projectName}
                    </Link>
                  ),
                },
                {
                  label: <FormattedMessage id="field.issueDate" />,
                  value: formatDate(collectiveInvoice.issueDate),
                },
                {
                  label: <FormattedMessage id="field.paymentTerm" />,
                  value: collectiveInvoice.paymentTerm,
                },
                !isPaid
                  ? {
                      label: 'Payment due in',
                      value: (
                        <Status type={dayjs(collectiveInvoice.dueDate).diff(dayjs(), 'd') >= 0 ? 'success' : 'error'}>
                          <FormattedMessage
                            id="field.XDaysLeft"
                            values={{ x: dayjs(collectiveInvoice.dueDate).diff(dayjs(), 'd') }}
                          />
                        </Status>
                      ),
                    }
                  : undefined,
                {
                  label: <FormattedMessage id="field.dueDate" />,
                  value: formatDate(collectiveInvoice.dueDate),
                },
                {
                  label: <FormattedMessage id="field.totalAmount" />,
                  value: renderAmount({ amount: collectiveInvoice.amountWithVat as number }),
                },
                {
                  label: <FormattedMessage id="field.vatAmount" />,
                  value: renderAmount({
                    amount: getCollectiveInvoiceVatAmount(
                      collectiveInvoice.subInvoice as SubInvoiceInDebtorCollectiveInvoice[],
                    ),
                  }),
                },
              ]}
            />
            <CollectiveInvoiceAttachments collectiveInvoice={collectiveInvoice} />
          </Grid>
        </Grid>
        <CollectiveInvoiceBreakdown data={collectiveInvoice.subInvoice as SubInvoiceInDebtorCollectiveInvoice[]} />
      </Tabs>
    </>
  );
};

export default CollectiveInvoiceDetails;
