const breakpoints = {
  xs: 320,
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1400,
} as const;

type Breakpoint = keyof typeof breakpoints;
type BreakpointsProps = typeof breakpoints;

export type WithBreakpointsProps = {
  currentBreakpoint: Breakpoint;
  breakpoints: BreakpointsProps;
};

export default breakpoints;
