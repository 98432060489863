import { useReducer } from 'react';

import { SortDirection, ITableColumn } from './Table.types';

export type TableState<D> = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: D[];
  disabledRows: D[];
  expandedRows: D[];
  selectedColumn?: ITableColumn<D>;
  sortDirection: SortDirection;
  toggleOnSelectedRowsChange?: boolean;
  restingCheckbox?: boolean;
};

export type Action<D> =
  | SingleRowAction<D>
  | SelectAllRowsAction<D>
  | MultipleRowsAction<D>
  | ClearSelectedRowsAction
  | SortAction<D>
  | ExpandRowAction<D>
  | PreExpandRows<D>
  | ResetCheckboxesAction;

export interface ResetCheckboxesAction {
  type: 'RESET_CHECKBOXES';
}

export interface SelectAllRowsAction<D> {
  type: 'SELECT_ALL_ROWS';
  selectableRows: D[];
}

export interface SingleRowAction<D> {
  type: 'SELECT_SINGLE_ROW';
  keyField: keyof D;
  row: D;
  isSelected: boolean;
  selectableRowsCount: number;
}

export interface MultipleRowsAction<D> {
  type: 'SELECT_MULTIPLE_ROWS';
  selectedRows: D[];
  disabledRows: D[];
  selectableRowsCount: number;
}

export interface PreExpandRows<D> {
  type: 'PRE_EXPAND_ROWS';
  preExpandedRows: D[];
}

export interface ClearSelectedRowsAction {
  type: 'CLEAR_SELECTED_ROWS';
}

export interface SortAction<D> {
  type: 'SORT_CHANGE';
  sortDirection: SortDirection;
  selectedColumn: ITableColumn<D>;
}

export interface ExpandRowAction<D> {
  type: 'EXPAND_ROW';
  keyField: keyof D;
  row: D;
  isExpanded: boolean;
}

function tableReducer<D>(state: TableState<D>, action: Action<D>): TableState<D> {
  const toggleOnSelectedRowsChange = !state.toggleOnSelectedRowsChange;
  const selectedRowsDisabled = state.selectedRows.filter((row) => state.disabledRows.includes(row));
  switch (action.type) {
    case 'SELECT_ALL_ROWS': {
      const { selectableRows } = action;
      const allChecked = !state.allSelected;

      // Combine previous selected rows with the newly selected rows from the current page
      const resultSelectedRows = allChecked
        ? [...new Set([...state.selectedRows, ...selectableRows])]
        : state.selectedRows.filter((row) => state.disabledRows.includes(row));

      return {
        ...state,
        allSelected: allChecked,
        selectedCount: resultSelectedRows.length,
        selectedRows: resultSelectedRows,
        toggleOnSelectedRowsChange,
      };
    }
    case 'CLEAR_SELECTED_ROWS': {
      return {
        ...state,
        allSelected: false,
        selectedCount: selectedRowsDisabled.length,
        selectedRows: selectedRowsDisabled, // disabled rows should not be unselected
        toggleOnSelectedRowsChange,
      };
    }
    case 'RESET_CHECKBOXES': {
      // This action will only reset the `allSelected` flag without affecting the rest of the state.
      return {
        ...state,
        allSelected: false,
        restingCheckbox: true,
        toggleOnSelectedRowsChange,
      };
    }
    case 'SELECT_SINGLE_ROW': {
      const { keyField, row, isSelected, selectableRowsCount } = action;

      if (!isSelected) {
        return {
          ...state,
          selectedCount: state.selectedRows.length > 0 ? state.selectedRows.length - 1 : 0,
          allSelected: false,
          restingCheckbox: false,
          selectedRows: state.selectedRows.filter((selectedRow) => selectedRow[keyField] !== row[keyField]),
          toggleOnSelectedRowsChange,
        };
      }
      const selectedRows = [...state.selectedRows, row];
      return {
        ...state,
        selectedCount: selectedRows.length,
        restingCheckbox: false,
        // selectedRowsDisabled should be excluded from allSelected calculations
        allSelected: selectedRows.length - selectedRowsDisabled.length === selectableRowsCount,
        selectedRows: selectedRows,
        toggleOnSelectedRowsChange,
      };
    }
    case 'SELECT_MULTIPLE_ROWS': {
      const { selectedRows, disabledRows, selectableRowsCount } = action;

      return {
        ...state,
        selectedCount: selectedRows.length,
        allSelected: selectedRows.length === selectableRowsCount,
        selectedRows,
        restingCheckbox: false,
        disabledRows: disabledRows,
        toggleOnSelectedRowsChange,
      };
    }
    case 'SORT_CHANGE': {
      const { sortDirection, selectedColumn } = action;
      const isSameColumnCurrentlySorted = state.selectedColumn?.id === selectedColumn.id;
      return {
        ...state,
        selectedColumn,
        sortDirection: isSameColumnCurrentlySorted ? sortDirection : SortDirection.ASC,
        allSelected: false,
        restingCheckbox: false,
        selectedCount: 0,
        selectedRows: [],
      };
    }
    case 'EXPAND_ROW': {
      const { keyField, row, isExpanded } = action;
      return {
        ...state,
        expandedRows: isExpanded
          ? [...state.expandedRows, row]
          : state.expandedRows.filter((expandedRow) => expandedRow[keyField] !== row[keyField]),
      };
    }
    case 'PRE_EXPAND_ROWS': {
      const { preExpandedRows } = action;
      return {
        ...state,
        expandedRows: preExpandedRows,
      };
    }
  }
}

export const useTableReducer = <D>({
  sortDirection,
  selectedColumn,
}: {
  sortDirection?: SortDirection | null;
  selectedColumn?: ITableColumn<D>;
}) => {
  return useReducer<React.Reducer<TableState<D>, Action<D>>>(tableReducer, {
    allSelected: false,
    restingCheckbox: false,
    selectedCount: 0,
    selectedRows: [],
    expandedRows: [],
    disabledRows: [],
    toggleOnSelectedRowsChange: false,
    sortDirection: sortDirection ?? SortDirection.DESC,
    selectedColumn,
  });
};
