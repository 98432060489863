import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  ApolloQueryResult,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql/error';
import { createContext, ReactElement, useContext, useEffect, useMemo, useState } from 'react';

import { noop } from '@bootstrap/utils';
import { captureException } from '@bootstrap/utils/sentry';
import { IApiContextProps, ILogoTypes } from '@connect/app/App.types';
import { useAuth, useUserGroup } from '@hub/auth-provider';
import desktopLogoDefault from '@ui/assets/img/finqle-logo-text.svg';

import { DEBTOR_LOGO, MERCHANT_LOGO } from './queries';
import config from '../configs/constants';

const TOKEN_UPDATE_INTERVAL = 60; // sec

const ApiContext = createContext<IApiContextProps>({
  isDefaultLogo: true,
  setAppLogos: noop,
  forceTokenUpdate: noop,
});

interface IApiProviderProps {
  children: ReactElement;
}

interface IApiConsumerProps {
  children: (props: IApiContextProps) => ReactElement;
}

interface ExtendedGraphQLError extends GraphQLError {
  code?: string;
}

export const ApiProvider = ({ children }: IApiProviderProps) => {
  const { token, getToken, updateToken, sessionId = '' } = useAuth();
  const { isMerchant } = useUserGroup();
  const desktopLogo = sessionStorage.getItem('base64Logo') || desktopLogoDefault;
  const [appLogos, setAppLogos] = useState<ILogoTypes>({ desktop: desktopLogo });
  const [tokenUpdateInterval, setTokenUpdateInterval] = useState(TOKEN_UPDATE_INTERVAL);
  const forceTokenUpdate = () => setTokenUpdateInterval(-1);

  const withToken = setContext(async () => {
    try {
      await updateToken(tokenUpdateInterval);
      if (tokenUpdateInterval !== TOKEN_UPDATE_INTERVAL) {
        setTokenUpdateInterval(TOKEN_UPDATE_INTERVAL);
      }
      return { token: getToken() };
    } catch (e) {
      captureException(e);
      return { token };
    }
  });

  const httpLink = useMemo(() => {
    return new HttpLink({ uri: isMerchant ? config.GRAPHQL_MERCHANT_URL : config.GRAPHQL_DEBTOR_URL });
  }, [isMerchant]);

  const authMiddleware = useMemo(() => {
    return new ApolloLink((operation, forward) => {
      const { token } = operation.getContext();
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
      });
      return forward(operation);
    });
  }, []);

  const logoutLink = onError(({ response }) => {
    if (response?.errors) {
      const error = response.errors[0] as ExtendedGraphQLError;

      if (error.code === '403' && error.message === 'Token validation failed') {
        window.location.reload();
      }
    }
  });

  const sentryLink = onError(({ networkError, operation, graphQLErrors }) => {
    if (networkError) {
      captureException(networkError, {
        extra: {
          operation,
          graphQLErrors,
        },
      });
    }
  });

  const client = useMemo(() => {
    return new ApolloClient({
      link: from([withToken, authMiddleware, sentryLink, logoutLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [authMiddleware, httpLink, logoutLink, sentryLink, withToken]);

  useEffect(() => {
    const setLogoFromQuery = ({ data }: ApolloQueryResult<any>) => {
      const base64Logo = data[isMerchant ? 'merchantLogo' : 'debtorLogo'];
      if (base64Logo) {
        setAppLogos({
          desktop: base64Logo,
          mobile: base64Logo,
        });
      }
    };
    if (!sessionStorage.getItem('base64Logo') || sessionStorage.getItem('sessionId') !== sessionId) {
      client.query({ query: isMerchant ? MERCHANT_LOGO : DEBTOR_LOGO }).then(setLogoFromQuery);
    }
  }, [client, isMerchant, sessionId]);

  useEffect(() => {
    if (appLogos.desktop && appLogos.desktop !== desktopLogoDefault) {
      sessionStorage.setItem('sessionId', sessionId);
      sessionStorage.setItem('base64Logo', appLogos.desktop);
    }
  }, [appLogos, sessionId]);

  if (!token) return children;

  return (
    <ApiContext.Provider
      value={{
        appLogos,
        isDefaultLogo: appLogos.desktop === desktopLogoDefault,
        setAppLogos,
        forceTokenUpdate,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApiContext.Provider>
  );
};

export const ApiConsumer = ({ children }: IApiConsumerProps) => (
  <ApiContext.Consumer>{(context) => children(context)}</ApiContext.Consumer>
);

export const useApi = (): IApiContextProps => {
  return useContext(ApiContext);
};
