// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import nl from 'dayjs/locale/nl';
import utc from 'dayjs/plugin/utc';
import mixpanel from 'mixpanel-browser';
import { IntlProvider } from 'react-intl';

import { TokenExpiredDialog } from '@bootstrap/components/TokenExpiredDialog';
import { isProduction } from '@bootstrap/constants/env';
import { useLoadLocaleData } from '@bootstrap/hooks/useLoadLocaleData';
import { LanguageEnum } from '@bootstrap/types/locale';
import { captureException } from '@bootstrap/utils/sentry';
import { IApiContextProps } from '@connect/app/App.types';
import { AuthProvider, TokenData } from '@hub/auth-provider';
import { Spinner } from '@ui/spinner';
import { Toaster } from '@ui/toast';

import { AppRouter } from './AppRouter';
import { CONNECT_SUPPORTED_LOCALES } from './constants';
import { loadLocaleData } from './effects';
import { GlobalStyles } from './globalStyles';
import { MainTemplate } from './MainTemplate';
import { ApiConsumer, ApiProvider } from './provider';
import config from '../configs/constants';

dayjs.extend(utc);

export const ConnectApp = () => {
  const { messages, locale, setLocale } = useLoadLocaleData({
    loadLocaleData,
    supportedLocales: CONNECT_SUPPORTED_LOCALES,
  });
  // Set locale to application language and Monday the first day of the week
  dayjs.locale(locale === LanguageEnum.NL ? nl : en, {
    weekStart: 1,
  });

  return messages ? (
    <IntlProvider locale={locale} messages={messages}>
      <AuthProvider
        onAuth={(authData: TokenData) => {
          if (authData) {
            const userEmail = authData['email'];
            const name = authData['name'];
            const role = authData['groups'][0];
            const account = authData['azp'];

            if (isProduction) {
              try {
                mixpanel.identify(userEmail);
                mixpanel.people.set({
                  $email: userEmail,
                  $name: name,
                  role,
                  account,
                });
              } catch (e) {
                captureException(e);
              }
            }
          }
        }}
        onTokenExpired={() => null}
        tokenExpiredComponent={<TokenExpiredDialog />}
        realm="MyFinqle"
        clientId="connect"
        url={config.AUTH_SERVER_URL}
        environment={config.ENVIRONMENT}
      >
        <ApiProvider>
          <ApiConsumer>
            {({ appLogos, isDefaultLogo }: IApiContextProps) => (
              <MainTemplate logos={appLogos} isDefaultLogo={isDefaultLogo} setLanguage={setLocale}>
                <Toaster />
                <GlobalStyles />
                <AppRouter />
              </MainTemplate>
            )}
          </ApiConsumer>
        </ApiProvider>
      </AuthProvider>
    </IntlProvider>
  ) : (
    <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
      <Spinner message="Loading..." />
    </div>
  );
};
